import React, { useEffect, useState } from "react";
import { Redirect, useHistory } from "react-router-dom";

import useForm from "react-hook-form";

//serviços
import api from "../../services/api";

//funções úteis
import validarCpf from "validar-cpf";
import { pontuar, capitular } from "../../services/utils";

//estilos
import "./styles/global.css";
import { Form, Container } from "./styles/styles";

export default () => {
  const { register, handleSubmit, errors } = useForm();
  const [cursos, setCursos] = useState([]);

  const [url] = useState(window.location.href.toString().split("/"));
  const [formatura, setFormatura] = useState(0);
  const [store, setStore] = useState(undefined);
  let history = useHistory();

  useEffect(() => {
    async function busca() {
      if (url.length > 4) {
        await api
          .get("/hash/" + url[url.length - 1])
          .then((ret) => {
            if (!ret.data.erro) {
              setCursos(ret.data.cursos);
              setFormatura(ret.data.formatura);
            } else {
              history.push("/nohash");
            }
          })
          .catch((err) => {
            history.push("/nohash");
          });
      } else {
        history.push("/nohash");
      }
    }
    busca();
  }, [url, history]);

  const onSubmit = async (data) => {
    data.AlNome = capitular(data.AlNome);
    data.AlEmail = data.AlEmail.trim().toLowerCase();
    data.AlCPF = pontuar(data.AlCPF, "cpf");
    data.AlFone = pontuar(data.AlFone, "cel");

    await api
      .post("/autostore", data)
      .then((str) => {
        setStore(str.data);
      })
      .catch((err) => alert(err));
  };

  return (
    <Container>
      {store !== undefined &&
        (store.status.created ? (
          <Redirect to="/success" />
        ) : (
          <Redirect to="/match" />
        ))}

      <Form onSubmit={handleSubmit(onSubmit)}>
        <h1>Auto-cadastro</h1>

        {errors.AlNome && (
          <span className="errors-autocad">{errors.AlNome.message}</span>
        )}
        <input
          type="text"
          name="AlNome"
          autoFocus
          placeholder="Digite o seu nome"
          /* onBlur={e => e.target.value = capitular(e.target.value)} */
          ref={register({
            required: "Você precisa preencher seu nome completo.",
            pattern: {
              value: /[A-Za-z]* [A-Za-z]*/,
              message: "Não esqueça do sobrenome.",
            },
          })}
        />

        {errors.AlCurso && (
          <span className="errors-autocad">{errors.AlCurso.message}</span>
        )}
        <select
          name="AlCurso"
          ref={register({
            validate: (value) =>
              value !== "0" || "Lembre-se de escolher seu curso.",
          })}
        >
          <option value="0">Escolha seu curso...</option>
          {cursos.map((curso) => (
            <option value={curso.id} key={curso.id}>
              {curso.curso}
            </option>
          ))}
        </select>

        {errors.AlEmail && (
          <span className="errors-autocad">{errors.AlEmail.message}</span>
        )}
        <input
          type="text"
          name="AlEmail"
          placeholder="Digite o seu e-mail"
          ref={register({
            required: "O preenchimento do email é obrigatório.",
            pattern: {
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
              message: "Este email não parece válido.",
            },
          })}
        />

        {errors.AlCPF && (
          <span className="errors-autocad">{errors.AlCPF.message}</span>
        )}
        <input
          type="text"
          name="AlCPF"
          placeholder="Digite seu cpf (apenas números)"
          ref={register({
            required: "O preenchimento do cpf é obrigatório.",
            maxLength: {
              value: 11,
              message: "Seu CPF tem mais números do que o necessário.",
            },
            validate: (value) => validarCpf(value) || "Este CPF não é válido.",
          })}
        />

        {errors.AlFone && (
          <span className="errors-autocad">{errors.AlFone.message}</span>
        )}
        <input
          type="text"
          name="AlFone"
          placeholder="Digite seu telefone com DDD (apenas números)"
          ref={register({
            required: "O preenchimento do celular é obrigatório.",
            maxLength: {
              value: 11,
              message: "Seu fone tem mais números do que o necessário.",
            },
            pattern: {
              value: /[0-9]{11}$/,
              message: "Seu número de celular não é válido.",
            },
          })}
        />

        <input
          type="hidden"
          name="AlFormatura"
          defaultValue={formatura}
          ref={register}
        />
        {/* {(formState.isValid && 'valid') || 'invalid'} */}
        <button className="autocad" type="submit">
          Enviar
        </button>
      </Form>
    </Container>
  );
};
