import React, { useContext, useState } from "react";
import { StateContext } from "../../context/Context";

import { Container } from "./styles";
import { useHistory } from "react-router-dom";
import Endereco from "../../components/Endereco";
import { faMapMarked } from "@fortawesome/free-solid-svg-icons";
import DashboardItem from "../Home/DashboardItem";

function Perfil(props) {
  const context = useContext(StateContext);
  const history = useHistory();

  return (
    <Container>
      <DashboardItem
        icon={faMapMarked}
        caption="Dados de localização"
        description="Mantenha seus dados de endereço atualizados."
        loading={false}
      >
        <Endereco view={true} perfil={true} />
      </DashboardItem>
    </Container>
  );
}

export default Perfil;
