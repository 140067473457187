import React, { useContext, useState } from "react";
import { StateContext } from "../../../context/Context";
import formas from "../../../services/formas_pagamento.json";
import {
  resumeCreditos,
  refreshFrete,
  moeda,
  iconSelector,
} from "../../../services/utils";

import { Container } from "./styles";
import voltar from "../../../assets/icons/svg/flat/rewind.svg";
import outro from "../../../assets/icons/svg/flat/repeat-1.svg";
import album from "../../../assets/icons/svg/flat/home-2.svg";
import { useHistory } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCashRegister } from "@fortawesome/free-solid-svg-icons";
import GeraPagamento from "../../../components/GeraPagamento";

function Pay() {
  const context = useContext(StateContext);
  const [formaSelected, setFormaSelected] = useState(0);
  const [pagarAgora, setPagarAgora] = useState(false);
  const history = useHistory();
  if (context.state.cart.length === 0) history.push("/");
  //Cálculo geral de cobrança

  const {
    AlFormDados: { FormDownImpressas },
    AlCredFtDigital,
    AlCredFtImpressa,
  } = context.state.cadastro;

  const creditosDados = {
    FormDownImpressas,
    AlCredFtDigital,
    AlCredFtImpressa,
  };

  const { totalImpressas, totalDigitais } = resumeCreditos(
    creditosDados,
    context.state.cart
  );

  const comFrete =
    context.state.checkout.frete.length > 0 && refreshFrete(context.state.cart)
      ? context.state.checkout.frete[0].valor
      : 0;

  const totalGeral =
    context.state.cart.reduce((a, v) => a + v.detalhes.valor * v.qt, 0) -
    (totalDigitais.val + totalImpressas.val) +
    comFrete;

  const formasFilter =
    totalGeral === 0
      ? formas.filter((it) => it.id === 100)
      : formaSelected === 0
        //? formas.filter((it) => it.id >= 2 && it.id != 3 && it.id < 100) //controlando para não aparecer outras (ainda) ;)
        ? formas.filter((it) => it.id >= 2 && it.id != 3 && it.id != 4 && it.id < 100) //apenas cartão, pix, boleto e crédito do pacote
        : formas.filter((it) => it.id === formaSelected);

  const handlePay = (id) => {
    return setPagarAgora(true);
  };

  return (
    <Container>
      <div className="container-cart">
        <section id="cart-header">
          <div>
            <h2>
              <FontAwesomeIcon icon={faCashRegister} /> Formas de pagamento:
            </h2>
            <span>Escolha a forma de pagamento que mais lhe atende.</span>
          </div>
          <div className="pay-total">
            <p>Total deste pedido: {moeda(totalGeral)}</p>
          </div>
        </section>
        <section id="cart-checkout">
          {pagarAgora ? (
            <button
              className="bt_checkout volta"
              type="button"
              title="Retorna à lista de produtos"
              onClick={() => history.push("/")}
            >
              <img src={album} className="icon32" alt="Continue escolhendo" />
              <span> Voltar ao Portal</span>
            </button>
          ) : (
            <button
              className="bt_checkout volta"
              type="button"
              title="Retorna ao pedido"
              onClick={() => history.push("cart")}
            >
              <img src={voltar} className="icon32" alt="Volta carrinho" />
              <span> Voltar ao Pedido</span>
            </button>
          )}
          {formaSelected > 0 && formaSelected < 100 && !pagarAgora && (
            <button
              className="bt_checkout outro"
              type="button"
              title="Escolhe outra forma"
              onClick={() => setFormaSelected(0)}
            >
              <img src={outro} className="icon32" alt="Outra forma" />
              <span> Escolher outra forma</span>
            </button>
          )}
        </section>
        <section id="pay-list">
          {formasFilter.map((it) => (
            <article
              className="item-pay-checkout"
              key={it.id}
              title={it.descricao}
              onClick={() => setFormaSelected(it.id)}
            >
              {iconSelector(it.id, formas, "icon-pay-checkout")}
              {formaSelected === 0 ? (
                <div className="pay-description">
                  <h3>{it.nome}</h3>
                  <p>{it.descricao}</p>
                  {it.id === 100 && <strong>Clique para continuar</strong>}
                </div>
              ) : (
                <div className="pay-confirm" onClick={() => handlePay(it.id)}>
                  <h3>Você escolheu {it.nome}</h3>
                  {pagarAgora ? (
                    <GeraPagamento
                      contrato={context.state.financeiro[0].ctid}
                      valor={totalGeral}
                      tipo="E-commerce"
                      forma={it.id}
                      comPedido={true}
                    />
                  ) : (
                    <p>
                      Clique novamente para confirmar ou escolha outra forma.
                    </p>
                  )}
                </div>
              )}
            </article>
          ))}
        </section>
      </div>
    </Container>
  );
}

export default Pay;
