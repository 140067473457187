import styled from "styled-components";

export const DashItem = styled.li`
  background-color: #fff;
  border-radius: 8px;
  padding: 24px;
  position: relative;

  .card-title {
    font-size: 1.2em;
    font-weight: 500;
    padding: 1px;
    border-bottom: 1px solid #ccc;
    .icon-card-title {
      margin-right: 15px;
    }
  }
  .card-description {
    font-size: 0.8em;
  }
  .evt-content {
    display: grid;
    border-collapse: collapse;
    min-width: 99%;
    max-width: 99%;
    grid-template-columns:
      minmax(100px, 1fr)
      minmax(100px, 1fr)
      minmax(100px, 1fr);
  }

  .loja-content {
    display: grid;
    border-collapse: collapse;
    min-width: 99%;
    max-width: 99%;
    grid-template-columns: minmax(100px, 1fr);
  }

  /* button {
    position: absolute;
    top: 24px;
    right: 24px;
    border: 0;
  } */
`;

export const DashboardItemBody = styled.div`
  max-height: 500px;
  overflow-y: auto;
  #load_list {
    margin: 10% auto;
    height: 3px;
  }
`;
