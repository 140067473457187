import React, { useState } from "react";
import { APP_URL } from "../../../configs/consts";
import { CtrItem, ExtrasItem } from "./styles";
import { foiPagoAvista, moeda, PagamentoAvista } from "../../../services/utils";
/* import { isAfter, parseISO, addDays } from "date-fns"; */
import FinancialItem from "../FinancialItem";

import GeraCobrancas from "../GeraCobrancas";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  faFileSignature,
  faEye,
  faHandHoldingUsd,
  faBoxes,
  faBoxOpen,
} from "@fortawesome/free-solid-svg-icons";

export default function ContractItem(props) {
  const { contrato, formatura, extras, parcelas } = props;
  const [showGboleto, setShowGboleto] = useState(false);
  const reajuste = formatura.FormReajuste >= 0 ? "reajuste" : "desconto";

  const totalContrato = contrato.AlParcelas.filter(
    (parc) => parc.parctipo === "Contrato" && parc.parcvalorpago > 0
  ).reduce((a, v) => a + v.parcvalorpago, 0);
  const totalExtras = extras.reduce((a, v) => a + v.exvalor, 0);

  /* const pagoavista = contrato.AlParcelas.filter(
    (parc) =>
      parc.valorpago === contrato.cttotal &&
      parc.parctipo === "Contrato" &&
      isAfter(parseISO(parc.parcpagoem), parseISO(formatura.FormLimiteAvista))
  ); */

  return (
    <CtrItem>
      <p className="contract-title">
        <FontAwesomeIcon
          className="icon-contract-title"
          icon={faFileSignature}
        />{" "}
        Contrato <strong>{contrato.ctid}</strong>{" "}
        {formatura.FormContrato && (
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={`${APP_URL}/portalsp/${formatura.FormContrato}`}
          >
            {" "}
            <FontAwesomeIcon
              title="Leia o pdf do seu contrato aqui na íntegra."
              icon={faEye}
            />
          </a>
        )}
      </p>
      <p className="contract-description">
        O valor total deste contrato é <strong>{moeda(formatura.FormValorPrazo + formatura.FormReajuste)}.</strong>{" "}
        {formatura.FormReajuste !== 0 &&
          `(Neste valor, está incluso um reajuste de ${moeda(formatura.FormReajuste)}).`}
        <br />
        {!foiPagoAvista(parcelas) && (
          <PagamentoAvista
            limite={formatura.FormLimiteAvista}
            avista={formatura.FormValor}
          />
        )}
      </p>
      <div className="contract-footer">
        <p className="contract-total">
          {totalContrato
            ? `Total Pago: ${moeda(totalContrato)}.`
            : `Você não tem pagamentos registrados.`}
        </p>
        {totalContrato ? <span className="contract-description">Obs.: O total pago contempla contrato, extras e reajustes.</span> :''}

        {showGboleto && (
          <GeraCobrancas
            contrato={contrato.ctid}
            totais={{
              pago: totalContrato,
              extras: totalExtras,
              reajustes: formatura.FormReajuste,
              avista: formatura.FormValor,
              aprazo: formatura.FormValorPrazo,
              limite: formatura.FormLimiteAvista,
            }}
            via={1}
          ></GeraCobrancas>
        )}
        {!showGboleto && (
          <button
            className="icone-e-texto"
            title="Clique para escolher uma forma e um valor para gerar um pagamento."
            onClick={() => setShowGboleto(!showGboleto)}
          >
            <FontAwesomeIcon icon={faHandHoldingUsd} /> Fazer um pagamento agora
          </button>
        )}
      </div>
      {contrato.AlParcelas &&
        contrato.AlParcelas.filter(
          (parc) =>
            (parc.parctipo === "Contrato" || parc.parctipo === "Extras") &&
            parc.parcvisivel === 1
        ).map((parcela) => (
          <FinancialItem key={parcela.parcid} parcela={parcela} />
        ))}
      <br />
      {totalExtras !== 0 && (
        <>
          <p className="contract-title">
            <FontAwesomeIcon className="icon-contract-title" icon={faBoxes} />{" "}
            Extras e Pacotes
          </p>
          <p className="contract-description">
            Pacotes e serviços extras adquiridos após a assinatura do contrato
          </p>
          
        {totalExtras !== 0 && <p className="contract-description">Seus extras somam <strong>{moeda(totalExtras)}.</strong></p>}
          {extras.map((it) => (
            <ExtrasItem key={it.excodigo}>
              <FontAwesomeIcon
                className="icon-extras"
                icon={faBoxOpen}
                title="Extras de pacote"
              />
              <span>{it.exdescricao}</span>
              <span>{moeda(it.exvalor)}</span>
            </ExtrasItem>
          ))}
        </>
      )}
    </CtrItem>
  );
}
