//production
export const BASE_URL = "https://sistema.spprod.com.br/api";
export const APP_URL = "https://sistema.spprod.com.br";
export const URL_RETORNO = "https://sistema.spprod.com.br";

//production
/* export const BASE_URL = "http://187.32.210.82:3737";
export const APP_URL = "http://187.32.210.82";
export const URL_RETORNO = "http://sistema.spprod.com.br"; */

//dev
/* export const APP_URL = "http://localhost";
export const BASE_URL = "http://localhost:3737";
export const URL_RETORNO = "http://localhost:3000"; */
/* export const APP_URL = "http://192.168.1.5";
export const BASE_URL = "http://192.168.1.5:3737";
export const URL_RETORNO = "http://192.168.1.5:3000"; */

export const BASE_URL_BANK = "https://mpag.bb.com.br/site/mpag/";
export const ALBUM_LIMIT = 50;
export const FILTER_FORMATURA = 199;
export const TAXA_BOLETO = 3.5;
export const ID_CONV = "322122";
export const REF_TRAN = "3210965";
export const TP_PAGAMENTO = "2";
export const TP_VIA_2 = "21";
export const INDICADOR_PESSOA = "1";
export const TP_DUPLICATA = "DM";

export const SEDEX_PADRAO = 30;

export const PARCELA_MINIMA = 10;
export const MAX_PARCELAS = 12;
export const MAX_SEM_JUROS = 1;

export const CHAVE_PIX = "03724078000147";
export const TITULAR_PIX = "SP Agencia de Eventos";
/* export const CHAVE_PIX = "21160808000106";
export const TITULAR_PIX = "Cs Producoes"; */
export const CIDADE_PIX = "Porto Alegre";
export const EMAIL_FINANCEIRO = "financeiro@spprod.com.br";
