import React, { useEffect, useState, useContext, useRef } from "react";
import { useHistory, withRouter } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import Modal from "react-modal";
import { add } from "date-fns";
import banner from "../../assets/banner_loja.jpg";

import api from "../../services/api";
import { handleGetCache, clearCart, moeda } from "../../services/utils";
import { ALBUM_LIMIT } from "./../../configs/consts";
import { StateContext } from "./../../context/Context";

/* import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"; */

import ContractItem from "./ContractItem";
import DashboardItem from "./DashboardItem";
import OrderItem from "./OrderItem";
import OrderDetails from "./OrderDetails";
import Uploads from "../Uploads";
import RelatorioPagamentos from "./RelatorioPagamentos";
import RelatorioArquivos from "./RelatorioArquivos";

import {
  faComments,
  faPhotoVideo,
  faFileContract,
  faShoppingBag,
  faCloudUploadAlt,
  faTools,
  faStream,
  faCommentDollar,
  faPrint,
  faTimes,
  faShoppingBasket,
  faMusic,
  faAt,
  faImages,
  faVideo,
  faLink,
} from "@fortawesome/free-solid-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  Corpo,
  Container,
  DashboardList,
  MsgItem,
  EvtItem,
  ToolsItem,
} from "./styles";
import { notificar } from "../../components/Notificador";
import { Recado } from "../../components/styled-components-global";

function Home(props) {
  let history = useHistory();
  const context = useContext(StateContext);
  const [bcast, setBcast] = useState({ todos: [], aluno: [] });
  const [fotos, setFotos] = useState({ todos: [], aluno: [] });
  const [pessoal, setPessoal] = useState([]);
  const [pedidos, setPedidos] = useState([]);
  const [detalhesPedido, setDetalhesPedido] = useState([]);
  const [msgPedido, setMsgPedido] = useState(null);
  const [pedResumido, setPedResumido] = useState([]);
  const [comissao, setComissao] = useState([]);
  const [financeiro, setFinanceiro] = useState([]);
  const [viewModal, setViewModal] = useState(false);
  const [viewRelPagamentos, setViewRelPagamentos] = useState(false);
  const [viewRelArquivos, setViewRelArquivos] = useState(false);
  const [viewUpsFormatura, setViewUpsFormatura] = useState(false);
  const [viewResumo, setViewResumo] = useState(false);
  const [minimoQuitado, setMinimoQuitado] = useState(false);
  const [percentualQuitado, setPercentualQuitado] = useState(0);

  const [isLoading, setIsLoading] = useState(true);
  const printThisRef = useRef();
  const handlePrintThis = useReactToPrint({
    content: () => printThisRef.current,
    documentTitle: "RelatórioSP",
  });

  const obterDados = async () => {
    const cacheLocal = localStorage.getItem("SPCart");
    if (cacheLocal && JSON.parse(cacheLocal).length) {
      context.actions.setCart(JSON.parse(cacheLocal));
    } else {
      const cacheCart = await handleGetCache("cart");
      if (cacheCart && cacheCart.content.length) {
        context.actions.setCart(cacheCart.content);
        notificar(
          ({ closeToast }) => (
            <p className="texto-toast">
              Parece que você deixou um pedido aberto em sua última visita.{" "}
              <br />
              <span className="bts-toast">
                <button
                  className="bt-toast"
                  onClick={() => history.push("/cart")}
                >
                  Ver
                </button>
                <button className="bt-toast" onClick={() => clearCart()}>
                  Descartar
                </button>
              </span>
            </p>
          ),
          /* `${prodCart.id} - ${prodCart.detalhes.nome} adicionado ao carrinho`, */
          null,
          "embaixo",
          null,
          null,
          25000
        );
      }
    }

    const bcs = await api.get(`/perfil/bcast`).then((dados) => {
      return dados.data;
    });

    const cad = await api.get(`/perfil/cadastro`).then((dados) => {
      return dados.data;
    });

    const fts = await api.get(`/perfil/fotos`).then((dados) => {
      return dados.data;
    });

    let fin = await api.get(`/perfil/financeiro`).then((dados) => {
      return dados.data;
    });

    const ped = await api.get(`/pedidos/consultar`).then((dados) => {
      return dados.data;
    });
    const com = await api.get(`/relatorios/comissao`).then((dados) => {
      return dados.data;
    });

    //controle de parcelas agrupadas

    fin[0].AlParcelas.filter((p) => p.parcagrupador === 1).map((it) => {
      it.parcvalor = fin[0].AlParcelas.filter(
        (p) => p.parcgrupo === it.parcid
      ).reduce((a, b) => a + b.parcvalor, it.parcvalor);
      it.parcacrescimo = fin[0].AlParcelas.filter(
        (p) => p.parcgrupo === it.parcid
      ).reduce((a, b) => a + b.parcacrescimo, it.parcacrescimo);
      it.parcdesconto = fin[0].AlParcelas.filter(
        (p) => p.parcgrupo === it.parcid
      ).reduce((a, b) => a + b.parcdesconto, it.parcdesconto);
      it.parcvalorpago = it.parcvalor;
    });
    fin[0].AlParcelas = fin[0].AlParcelas.filter((p) => p.parcgrupo === null);

    //consulta detalhes de um pedido para o modal
    const { retorno, tipo } = props.match.params;

    if (retorno && retorno.indexOf("-")) {
      //testa se endpoint tem padrão
      let ids = retorno.split("-");
      const parc = fin[0].AlParcelas.find((it) => it.parcid == ids[0]);
      let msg, cor;

      if (parc && parc.parcpagoem !== null) {
        msg =
          parc.parcvalor > 0
            ? `Seu pagamento de ${moeda(
              parc.parcvalor
            )} foi efetuado com sucesso. Obrigado.`
            : `Sua transação foi abonada pelos seus créditos conosco. Obrigado.`;
        cor = "verde";
      } else {
        msg = "Seu pagamento está sendo processado pela operadora de cartões.";
        cor = null;
      }

      switch (tipo) {
        case "E-commerce":
          handlePedidoDetails(ids[1], ped);
          setMsgPedido({
            msg: `${msg} Qualquer alteração de estado em seu PEDIDO será notificada por email.`,
            cor: cor,
          });
          break;
        case "Contrato":
        case "Extras":
          notificar(msg, cor, null, () => history.push("/"), null);
          break;
        default:
      }
    }

    //controle de mínimo quitado
    const tPagas = fin[0].AlParcelas.filter(
      (p) => p.parcpagoem !== null && p.parctipo === "Contrato"
    );
    const vPago = tPagas.reduce((ant, cur) => ant + cur.parcvalorpago, 0);

    //setagem de conteúdos para o portal
    setIsLoading(false);
    setBcast(bcs);
    setFotos(fts);
    setFinanceiro(fin);
    setPessoal(cad);
    setPedidos(ped);
    setComissao(com);

    context.actions.setPerfil({ financeiro: fin, cadastro: cad, pedidos: ped });
    setPercentualQuitado(cad.AlFormDados.FormMinimoPago);
    setMinimoQuitado(
      Boolean(
        vPago >= ((fin[0].cttotalprazo / 100) * cad.AlFormDados.FormMinimoPago) - 1
        //context.state.cadastro.AlFormDados.FormMinimoPago
      )
    );
  };
  useEffect(() => {
    obterDados();
  }, []);

  function handleCloseModal() {
    setViewModal(false);
    setViewRelPagamentos(false);
    setViewRelArquivos(false);
    setViewUpsFormatura(false);
    setViewResumo(false);
    setDetalhesPedido([]);
    setMsgPedido(null);
    setPedResumido([]);
    history.push("/");
  }

  function handleClickAlbum(ev, tp, lnk) {
    switch (tp) {
      case 0:
        return history.push(`/album/${ev}/${ALBUM_LIMIT}/1`);
      case 1:
      case 2:
        window.open(lnk, "_blank");
    }
  }

  function handleRelatorioPagamento() {
    setViewModal(true);
    setViewRelPagamentos(true);
  }

  function handleRelatorioArquivos() {
    setViewModal(true);
    setViewRelArquivos(true);
  }
  function handleUpsFormatura() {
    setViewModal(true);
    setViewUpsFormatura(true);
  }

  async function handlePedidoDetails(pedDetalhes, ped, e = null) {
    if (e) e.stopPropagation();
    const pedRes = ped.filter((it) => it.pedId == pedDetalhes)[0];
    const dadosPedido = await api.get(`/pedidos/detalhar/${pedDetalhes}`);
    if (dadosPedido.data) {
      setPedResumido(pedRes);
      setDetalhesPedido(dadosPedido.data);
      setViewResumo(true);
      setViewModal(true);
    }
  }

  const modalStyle = {
    content: {
      maxWidth: "90vw",
      minWidth: "300px",
      margin: "auto",
      fontSize: "14px !important",
    },
  };

  let botoesModalStyle = {
    padding: "15px",
  };

  const containerBotoesModalStyle = {
    display: "flex",
    justifyContent: "flex-end",
  };

  return (
    <Corpo>
      <Modal
        isOpen={viewModal}
        onRequestClose={() => handleCloseModal()}
        style={modalStyle}
        ref={printThisRef}
      >
        <p style={containerBotoesModalStyle}>
          {!viewUpsFormatura && (
            <>
              <button
                style={botoesModalStyle}
                onClick={handlePrintThis}
                title="Imprimir este relatório"
                className="icone"
              >
                <FontAwesomeIcon style={{ fontSize: 15 }} icon={faPrint} />
              </button>
              {/* <button
                style={botoesModalStyle}
                onClick={handleEmailThis}
                title="Mandar por email"
                className="icone"
              >
                <FontAwesomeIcon style={{ fontSize: 15 }} icon={faAt} />
              </button> */}
            </>
          )}
          <button
            style={botoesModalStyle}
            onClick={() => handleCloseModal()}
            className="icone"
            title="Cancelar e fechar"
          >
            <FontAwesomeIcon icon={faTimes} />
          </button>
        </p>
        {viewRelPagamentos && (
          <RelatorioPagamentos
            dados={comissao}
            formatura={pessoal.AlFormDados}
          />
        )}
        {viewRelArquivos && (
          <RelatorioArquivos dados={comissao} formatura={pessoal.AlFormDados} />
        )}
        {viewUpsFormatura && <Uploads tipo="formatura" />}
        {viewResumo && (
          <OrderDetails
            pedido={pedResumido}
            detalhes={detalhesPedido}
            mensagem={msgPedido}
          />
        )}
      </Modal>
      {/* <p style={{fontSize: "0.6em"}}>{`Sua sessão expira em ${(expire /(60000)).toFixed(0)} minutos.` }</p> */}
      <Container>
        <DashboardList>
          {/* CONTRATOS */}
          {pessoal.AlFormDados ? (
            <DashboardItem
              icon={faFileContract}
              caption="Contratos e Pacotes"
              description="Todas as informações sobre os seus produtos contratados e formas
            de pagamento."
              loading={isLoading}
            >
              {financeiro.map((contrato) => (
                <ContractItem
                  key={contrato.ctid}
                  contrato={contrato}
                  formatura={pessoal.AlFormDados}
                  parcelas={financeiro[0].AlParcelas}
                  extras={pessoal.AlExtras}
                />
              ))}
            </DashboardItem>
          ) : null}


          {/* CONTEÚDOS */}
          {(fotos.aluno.length || fotos.todos.length) && !pessoal.AlRestrito ? (
            <DashboardItem
              icon={faPhotoVideo}
              caption="Gerenciador de
              Conteúdos"
              description="Fotos, vídeos e links de seus eventos realizados por nós."
              loading={!(fotos.aluno.length || fotos.todos.length)}
            >
              {minimoQuitado ? (
                <>
                  {fotos.aluno.filter((it) => it.evtpmidia === 0).length ||
                    fotos.todos.filter((it) => it.evtpmidia === 0).length ? (
                    <>
                      <p
                        className="card-title"
                        style={{
                          textAlign: "center",
                          marginTop: "15px",
                          color: "#999",
                        }}
                      >
                        <FontAwesomeIcon
                          className="icon-card-title"
                          icon={faImages}
                        />{" "}
                        Fotos dos Eventos
                      </p>
                      <div className="evt-content">
                        {fotos.aluno
                          .filter((it) => it.evtpmidia === 0)
                          .map((ft) => (
                            <EvtItem
                              key={ft.evid}
                              onClick={() =>
                                handleClickAlbum(
                                  ft.evid,
                                  ft.evtpmidia,
                                  ft.evlnkmidia
                                )
                              }
                            >
                              <p className="evt-titulo">{ft.evnome}</p>
                              <img src={ft.evcaminho} alt="Album de evento" />
                              <p className="evt-rodape">
                                {Intl.DateTimeFormat("pt-BR").format(
                                  add(new Date(ft.evdata), { days: 1 })
                                )}
                              </p>
                            </EvtItem>
                          ))}
                        {fotos.todos
                          .filter((it) => it.evtpmidia === 0)
                          .map((ft) => (
                            <EvtItem
                              key={ft.evid}
                              onClick={() =>
                                handleClickAlbum(
                                  ft.evid,
                                  ft.evtpmidia,
                                  ft.evlnkmidia
                                )
                              }
                            >
                              <p className="evt-titulo">{ft.evnome}</p>
                              <img src={ft.evcaminho} alt="Album de evento" />
                              <p className="evt-rodape">
                                {Intl.DateTimeFormat("pt-BR").format(
                                  add(new Date(ft.evdata), { days: 1 })
                                )}
                              </p>
                            </EvtItem>
                          ))}
                      </div>
                    </>
                  ) : null}
                  {fotos.aluno.filter((it) => it.evtpmidia === 1).length ||
                    fotos.todos.filter((it) => it.evtpmidia === 1).length ? (
                    <>
                      <p
                        className="card-title"
                        style={{
                          textAlign: "center",
                          marginTop: "15px",
                          color: "#999",
                        }}
                      >
                        <FontAwesomeIcon
                          className="icon-card-title"
                          icon={faVideo}
                        />{" "}
                        Videos dos Eventos
                      </p>
                      <div className="evt-content">
                        {fotos.aluno
                          .filter((it) => it.evtpmidia === 1)
                          .map((ft) => (
                            <EvtItem
                              key={ft.evid}
                              onClick={() =>
                                handleClickAlbum(
                                  ft.evid,
                                  ft.evtpmidia,
                                  ft.evlnkmidia
                                )
                              }
                            >
                              <p className="evt-titulo">{ft.evnome}</p>
                              <img src={ft.evcaminho} alt="Album de evento" />
                              <p className="evt-rodape">
                                {Intl.DateTimeFormat("pt-BR").format(
                                  add(new Date(ft.evdata), { days: 1 })
                                )}
                              </p>
                            </EvtItem>
                          ))}
                        {fotos.todos
                          .filter((it) => it.evtpmidia === 1)
                          .map((ft) => (
                            <EvtItem
                              key={ft.evid}
                              onClick={() =>
                                handleClickAlbum(
                                  ft.evid,
                                  ft.evtpmidia,
                                  ft.evlnkmidia
                                )
                              }
                            >
                              <p className="evt-titulo">{ft.evnome}</p>
                              <img src={ft.evcaminho} alt="Album de evento" />
                              <p className="evt-rodape">
                                {Intl.DateTimeFormat("pt-BR").format(
                                  add(new Date(ft.evdata), { days: 1 })
                                )}
                              </p>
                            </EvtItem>
                          ))}
                      </div>
                    </>
                  ) : null}
                  {fotos.aluno.filter((it) => it.evtpmidia === 2).length ||
                    fotos.todos.filter((it) => it.evtpmidia === 2).length ? (
                    <>
                      <p
                        className="card-title"
                        style={{
                          textAlign: "center",
                          marginTop: "15px",
                          color: "#999",
                        }}
                      >
                        <FontAwesomeIcon
                          className="icon-card-title"
                          icon={faLink}
                        />{" "}
                        Links para Conteúdo dos Eventos
                      </p>
                      <div className="evt-content">
                        {fotos.aluno
                          .filter((it) => it.evtpmidia === 2)
                          .map((ft) => (
                            <EvtItem
                              key={ft.evid}
                              onClick={() =>
                                handleClickAlbum(
                                  ft.evid,
                                  ft.evtpmidia,
                                  ft.evlnkmidia
                                )
                              }
                            >
                              <p className="evt-titulo">{ft.evnome}</p>
                              <img src={ft.evcaminho} alt="Album de evento" />
                              <p className="evt-rodape">
                                {Intl.DateTimeFormat("pt-BR").format(
                                  add(new Date(ft.evdata), { days: 1 })
                                )}
                              </p>
                            </EvtItem>
                          ))}
                        {fotos.todos
                          .filter((it) => it.evtpmidia === 2)
                          .map((ft) => (
                            <EvtItem
                              key={ft.evid}
                              onClick={() =>
                                handleClickAlbum(
                                  ft.evid,
                                  ft.evtpmidia,
                                  ft.evlnkmidia
                                )
                              }
                            >
                              <p className="evt-titulo">{ft.evnome}</p>
                              <img src={ft.evcaminho} alt="Album de evento" />
                              <p className="evt-rodape">
                                {Intl.DateTimeFormat("pt-BR").format(
                                  add(new Date(ft.evdata), { days: 1 })
                                )}
                              </p>
                            </EvtItem>
                          ))}
                      </div>
                    </>
                  ) : null}
                </>
              ) : (
                <Recado>
                  Para ter acesso aos eventos que realizamos você precisa ter
                  quitado ao menos {percentualQuitado}% do valor de seu
                  contrato.
                </Recado>
              )}
            </DashboardItem>
          ) : null}

          {/* LOJA */}
          {!pessoal.AlRestrito ? (
            <DashboardItem
              icon={faShoppingBasket}
              caption="Loja de Produtos"
              description="Adquira produtos especialmente preparados para você."
              loading={!(fotos.aluno.length || fotos.todos.length)}
            >
              {minimoQuitado ? (
                <div className="loja-content">
                  <EvtItem onClick={() => history.push("/produtos")}>
                    <img src={banner} alt="Album de evento" />
                  </EvtItem>
                </div>
              ) : (
                <Recado>
                  Para ter acesso aos produtos exclusivos que oferecemos você
                  precisa ter quitado ao menos {percentualQuitado}% do valor de
                  seu contrato.
                </Recado>
              )}
            </DashboardItem>
          ) : null}

          {/* MURAL */}
          <DashboardItem
            icon={faComments}
            caption="Mural da SP Produtora"
            description="Comunicados para a turma e recados para você."
            loading={isLoading}
          >
            {/* <MsgItem>
              <p>
                <strong>Caro formando,</strong> <br />
                estamos criando um portal <u>totalmente novo</u> para lhe
                atender. Vamos liberar <strong>gradualmente</strong> várias
                funções desta plataforma.
                <br />
                Caso encontre algum problema na operação deste portal, você pode
                relatar diretamente à equipe de desenvolvimento pelo email{" "}
                <a href="mailto:dev@spprod.com.br">dev@spprod.com.br</a>
                .<br />
                Contamos com sua colaboração para melhorarmos esta ferramenta.
              </p>
              <p className="msg-rodape">
                <strong>#fiqueemcasa</strong> Todos pela erradicação da
                COVID-19.
              </p>
            </MsgItem> */}
            {bcast.todos.map((msg) => (
              <MsgItem key={msg.crmid}>
                <p>{msg.crmdesc}</p>
                <p className="msg-rodape">
                  em{" "}
                  {Intl.DateTimeFormat("pt-BR").format(
                    new Date(msg.crmtimestamp)
                  )}
                </p>
              </MsgItem>
            )) || "Sem mensagens para sua turma."}
            {bcast.aluno.map((msg) => (
              <MsgItem key={msg.crmid}>
                <p>{msg.crmdesc}</p>
                <p className="msg-rodape">
                  em{" "}
                  {Intl.DateTimeFormat("pt-BR").format(
                    new Date(msg.crmtimestamp)
                  )}
                </p>
              </MsgItem>
            )) || "Sem mensagens para você."}
          </DashboardItem>

          {/* PEDIDOS */}
          {pedidos.length && !pessoal.AlRestrito ? (
            <DashboardItem
              icon={faShoppingBag}
              caption="Andamento dos Pedidos"
              description="Estado de cada pedido seu feito em nosso sistema."
              loading={isLoading}
            >
              {pedidos.map((ped) => (
                <OrderItem
                  pedido={ped}
                  key={ped.pedId}
                  onClick={(e) => handlePedidoDetails(ped.pedId, pedidos, e)}
                />
              ))}
            </DashboardItem>
          ) : null}

          {/* ARQUIVOS */}
          {!pessoal.AlRestrito ? (
            <DashboardItem
              icon={faCloudUploadAlt}
              caption="Controle de Arquivos"
              description="Uploads de material para ilustrar a sua colação e seus produtos do pacote."
              loading={isLoading}
            >
              <Uploads tipo="individual" />
            </DashboardItem>
          ) : null}

          {/* FERRAMENTAS DA COMISSÃO */}
          {comissao && !pessoal.AlRestrito ? (
            <DashboardItem
              icon={faTools}
              caption="Ferramentas da Comissão"
              description="Ferramentas para ajudar você a gerenciar sua turma."
              loading={isLoading}
            >
              {/* <ToolsItem>
              <FontAwesomeIcon className="icon-tools" icon={faCalendarDay} />{" "}
              Protocolo de solicitação de eventos
            </ToolsItem>
            <ToolsItem>
              <FontAwesomeIcon className="icon-tools" icon={faTshirt} />{" "}
              Protocolo de solicitação de camisetas
            </ToolsItem> */}
              <ToolsItem onClick={() => handleRelatorioPagamento()}>
                <FontAwesomeIcon
                  className="icon-tools"
                  icon={faCommentDollar}
                />{" "}
                Relatório de pagamentos da turma
              </ToolsItem>
              <ToolsItem onClick={() => handleRelatorioArquivos()}>
                <FontAwesomeIcon className="icon-tools" icon={faStream} />{" "}
                Relatório de arquivos enviados pela turma
              </ToolsItem>

              <ToolsItem onClick={() => handleUpsFormatura()}>
                <FontAwesomeIcon className="icon-tools" icon={faMusic} />{" "}
                Carregar músicas para a formatura
              </ToolsItem>
              {/* <ToolsItem onClick={() => handlePedidosDetails()}>
                <FontAwesomeIcon className="icon-tools" icon={faShoppingBag} />{" "}
                Pedidos
              </ToolsItem> */}
            </DashboardItem>
          ) : null}
        </DashboardList>
      </Container>
    </Corpo>
  );
}

export default withRouter(Home);
