import React from "react";

import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { DashItem, DashboardItemBody } from "./styles";

export default function DashboardItem(props) {
  const { icon, caption, description, loading, children } = props;
  return (
    <DashItem>
      <p className="card-title">
        <FontAwesomeIcon className="icon-card-title" icon={icon} /> {caption}
      </p>
      <p className="card-description"> {description}</p>
      <Loader id="load_list" visible={loading} type="ThreeDots" color="#666" />
      <DashboardItemBody>{!loading && children}</DashboardItemBody>
    </DashItem>
  );
}
