import React, { Component } from "react";

import { DropContainer, UploadMessage } from "./styles";

import Dropzone from "react-dropzone";

export default class Upload extends Component {
  renderDragMessage = (isDragActive, isDragReject) => {
    if (!isDragActive) {
      return <UploadMessage>Clique ou arraste arquivos aqui...</UploadMessage>;
    }

    if (isDragReject) {
      return <UploadMessage type="error">Arquivo não suportado</UploadMessage>;
    }

    return <UploadMessage type="success">Solte os arquivos aqui</UploadMessage>;
  };

  render() {
    const { onUpload, tipo } = this.props;
    let multi = false;
    let accept = [];
    switch (tipo) {
      case "formatura":
        accept = ["audio/mp3", "audio/mpeg3", "audio/mpeg", "audio/x-mpeg-3"];
        multi = true;
        break;
      case "individual":
        accept = [
          "audio/mp3",
          "audio/mpeg3",
          "audio/mpeg",
          "audio/x-mpeg-3",
          "image/png",
          "image/jpeg",
        ];
        break;
    }
    return (
      <Dropzone accept={accept} onDropAccepted={onUpload} multiple={multi}>
        {({ getRootProps, getInputProps, isDragActive, isDragReject }) => (
          <DropContainer
            {...getRootProps()}
            isDragActive={isDragActive}
            isDragReject={isDragReject}
          >
            <input {...getInputProps()} />
            {this.renderDragMessage(isDragActive, isDragReject)}
          </DropContainer>
        )}
      </Dropzone>
    );
  }
}
