import React from "react";

import "./styles/global.css";
import { Mensagem, Container } from "./styles/styles";
import logo from "../../assets/logoSP.png";

export default () => {
  return (
    <Container>
      <Mensagem>
        <h1>Tudo certo! Você já está cadastrado no sistema.</h1>
        <p>Qualquer dúvida entre em contato com sua comissão.</p>
        <a className="auto" href="http://sistema.spprod.com.br/signup">
          Aproveite para criar seu usuário em nosso portal. Clique aqui.
        </a>
      </Mensagem>
    </Container>
  );
};
