import styled, { createGlobalStyle } from "styled-components";
import "react-circular-progressbar/dist/styles.css";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

export default createGlobalStyle`
  @import url('https://fonts.googleapis.com/css?family=Baloo+Da+2:400,500,700&display=swap');

  *{
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
  }

  a{
    text-decoration: none;
    :visited{
      color: #555;
    }
  }

  body{
    background: #edf7ff;
    font: 14px 'Baloo Da 2', sans-serif;
    color: #666;
    -webkit-font-smoothing: antialiased !important;
  }

  button.icone, a.icone {
    background-color: transparent;
    color: #555;
    border: none;
    transition: color 0.5s;
    display: flex;
    align-items: center;
  }
  button.icone:hover, a.icone:hover{
    color: #999;
  }

  button.icone-e-texto, #pagCartao {
    padding: 10px;
    text-decoration: none;
    margin: 10px !important;
    border: 1px solid #888;
    background-color: white;
    transition: background-color 0.5s;
    width: 97%;
    :hover {
      color: white;
      background-color: #888;
      transition: background-color 0.5s;
    }
  }

  button.icone-e-texto-vertical {
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    margin: 10px !important;
    border: 1px solid #888;
    background-color: white;
    transition: background-color 0.5s;
    width: 97%;
    svg{
      font-size: 2rem;
    }
    :hover {
      color: white;
      background-color: #888;
      transition: background-color 0.5s;
    }
  }

  button.bt-toast {
    border: 1px solid #ffffff22;
    width: 100%;
    margin: 0;
    margin-top: 5px;
    font-weight: 600;
    background: none;
    padding: 5px;
    color: #fff;
    font-size: 1.1em;
    :hover{
      color: #ccc;
      background-color: #ffffff55;
    }
  }
  p.texto-toast{
    text-align: center;
  }

  span.bts-toast{
    display: flex;
    justify-content: space-between;
    flex-direction: row;
  }

  .focus_me {
    animation: blinker 300ms linear 10;
  }

  @keyframes blinker {
    50% {
      box-shadow: 0 0 4px #e11200;
    }
  }
  

  html, #root{
    height: 100%;
  }

  .mini-dica{
    font-size: 0.8rem;
  }

  .bt-link{
    background: none;
    border: none;
    font-size: 0.8em;
    color: #7159c1;
    cursor: pointer;
    :hover{
      color: #333;
    }
  }

  .tour-content{
    p{
      font-style: italic;
      font-size: 0.9em;
    }
    img{
      width: 280px;
    }
  }
  .tour-init{
    display:flex;
    justify-content: space-between;
    align-items: center;

    p{
      font-style: italic;
      font-size: 0.9em;
      max-width: 50%;
    }
    img{
      width: 120px;
    }
  }
`;
