import React from "react";
import {
  format,
  parseISO,
  isBefore,
  isAfter,
  formatDistanceToNow,
  add,
  differenceInMonths,
} from "date-fns";
import ptBR from "date-fns/locale/pt-BR";
import api from "../services/api";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBarcode,
  faMoneyCheckAlt,
  faMoneyBill,
  faCreditCard,
  faWallet,
  faPiggyBank,
  faQrcode,
} from "@fortawesome/free-solid-svg-icons";

import GeraPagamento from "../components/GeraPagamento";
import Pix from "./Pix";

//Funções auxiliares

export const handleSaveCache = async (tipo, content) => {
  return await api.post(`/cache`, { tipo, content });
};

export const handleGetCache = async (tipo) => {
  const dados = await api.get(`/cache/${tipo}`);
  return dados.data[0] || false;
};

export const handleClearCache = async (tipo) => {
  return await api.delete(`/cache/${tipo}`);
};

export const capitular = (text) => {
  try {
    var words = text.trim().toLowerCase().split(" ");
    const preposicoes = ["de", "do", "da", "das", "dos", "e", "y", "ao", "aos"];

    for (var a = 0; a < words.length; a++) {
      var w = words[a];
      if (!preposicoes.includes(w)) {
        words[a] = w[0].toUpperCase() + w.slice(1);
      }
    }
    return words.join(" ");
  } catch (err) {
    return text;
  }
};

export const pontuar = (text, tipo) => {
  var doc = text.trim();
  if (tipo === "cpf") {
    return (
      doc.substr(0, 3) +
      "." +
      doc.substr(3, 3) +
      "." +
      doc.substr(6, 3) +
      "-" +
      doc.substr(9, 2)
    );
  } else if (tipo === "cel") {
    return (
      "(" + doc.substr(0, 2) + ") " + doc.substr(2, 5) + "-" + doc.substr(7, 4)
    );
  } else {
    return doc;
  }
};

export const moeda = (dado) => {
  return (
    Intl.NumberFormat("pt-BR", {
      style: "currency",
      currency: "BRL",
    }).format(dado) || dado
  );
};

export const databoa = (data = null, longo = false) => {
  if (data === null || data === "now") {
    const opt = {
      weekday: "long",
      month: "2-digit",
      day: "2-digit",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    };
    const frt = new Intl.DateTimeFormat("pt-BR", longo ? opt : {});
    return frt.format(Date.now());
  } else {
    return format(parseISO(data), "dd/MM/yyyy", { locale: ptBR }) || data;
  }
};

export const getMoney = (str) => {
  return parseInt(str.replace(/[\D]+/g, ""));
};

export const formatReal = (int) => {
  var tmp = int + "";
  tmp = tmp.replace(/([0-9]{2})$/g, ",$1");
  if (tmp.length > 6) tmp = tmp.replace(/([0-9]{3}),([0-9]{2}$)/g, ".$1,$2");

  return tmp;
};

export const refreshFrete = (cart) => {
  const digitais = cart.filter((it) => it.slug === "digital").length;
  if (cart.length === digitais) {
    return false;
  } else {
    return true;
  }
};

export const focusMe = (classTarget) => {
  const el = document.getElementsByClassName(classTarget);
  el[0].classList.toggle("focus_me");
  setTimeout(() => {
    el[0].classList.toggle("focus_me");
  }, 3200);
};

export const resumeCreditos = (dados, cart) => {
  let dig_imp = { qt: 0, val: 0 },
    dig = { qt: 0, val: 0 },
    imp = { qt: 0, val: 0 };

  cart.forEach((it) => {
    if (!it.ignorarCreditos) {
      switch (it.slug) {
        case "dig+imp":
          if (dados.AlCredFtImpressa >= dig_imp.qt + parseInt(it.qt)) {
            dig_imp.qt += parseInt(it.qt);
            dig_imp.val += it.detalhes.valor * it.qt;
          } else {
            while (dados.AlCredFtImpressa !== dig_imp.qt) {
              dig_imp.qt++;
              dig_imp.val += it.detalhes.valor;
            }
          }
          break;
        case "digital":
          if (dados.AlCredFtDigital > dig.qt) {
            dig.qt++;
            dig.val += it.detalhes.valor;
          }
          break;
        case "15x21":
          if (dados.AlCredFtImpressa >= imp.qt + parseInt(it.qt)) {
            imp.qt += parseInt(it.qt);
            imp.val += it.detalhes.valor * it.qt;
          } else {
            while (dados.AlCredFtImpressa !== imp.qt) {
              imp.qt++;
              imp.val += it.detalhes.valor;
            }
          }
          break;
        default:
      }
    }
  });
  return {
    totalImpressas: dados.FormDownImpressas === 1 ? dig_imp : imp,
    totalDigitais: dig,
  };
};

export const clearCart = (quem = "") => {
  handleClearCache("cart");
  localStorage.setItem("SPCart", "[]");
  localStorage.setItem(
    "SPCheckout",

    JSON.stringify({
      frete: {},
      descontos: [],
      bruto: 0,
      liquido: 0,
    })
  );
};

export const stringToList = (texto, classe) => {
  const txt = texto.replace(/(\r\n|\n\r|\r|\n)/g, "|");
  const t = txt.split("|");
  return (
    <ul className={classe}>
      {t.map((tx) => (
        <li>{tx}</li>
      ))}
    </ul>
  );
};

export const calculaMaxParcelas = (prazo, disponiveis) => {
  let maxParcelas = 1;
  const dtLimite = add(parseISO(prazo.data), {
    months: prazo.limiteCartao,
  });

  const mxParc = differenceInMonths(dtLimite, new Date()); //talvez seja necessário somar 1
  if (mxParc < 1) {
    //limita entre 1 e 12 parcelas
    maxParcelas = 1;
  } else if (mxParc > disponiveis) {
    maxParcelas = disponiveis;
  } else {
    maxParcelas = mxParc;
  }
  return maxParcelas;
};

export const foiPagoAvista = (parcelas) => {
  return Boolean(
    parcelas.filter((p) => p.parcpagoem !== null && p.parcdescavista === 1)
      .length
  );
};

export const debounce = (func, timeout = 1000) => {
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func.apply(this, args);
    }, timeout);
  };
};

export const tiracento = (str) => {
  if (str === undefined) return str;

  let com_acento =
    "ÀÁÂÃÄÅÆÇÈÉÊËÌÍÎÏÐÑÒÓÔÕÖØÙÚÛÜÝŔÞßàáâãäåæçèéêëìíîïðñòóôõöøùúûüýþÿŕ";
  let sem_acento =
    "AAAAAAACEEEEIIIIDNOOOOOOUUUUYRsBaaaaaaaceeeeiiiionoooooouuuuybyr";
  let novastr = "";
  for (let i = 0; i < str.length; i++) {
    let troca = false;
    for (let a = 0; a < com_acento.length; a++) {
      if (str.substr(i, 1) == com_acento.substr(a, 1)) {
        novastr += sem_acento.substr(a, 1);
        troca = true;
        break;
      }
    }
    if (troca == false) {
      novastr += str.substr(i, 1);
    }
  }
  return novastr;
};

export const criaPix = ({
  chave,
  mensagem,
  titular,
  cidade,
  transacao,
  valor,
}) => {
  const pix = new Pix(chave, mensagem, titular, cidade, transacao, valor);
  return pix.getPayload();
};

//Componentes auxiliares

export const Parcelador = (props) => {
  const {
    total,
    disponiveis,
    minimo: valorMinimo,
    disabled: avista,
    prazo,
    defaultValue: selecionadas,
  } = props;
  let maxParcelas = 1;
  if (!avista) {
    maxParcelas = calculaMaxParcelas(prazo, disponiveis);
  } else {
    maxParcelas = selecionadas;
  }

  let parcelas = [];
  for (let i = 1; i <= maxParcelas; i++) {
    let montante =
      i <= prazo.juro.sem ? total : total + (total / 100) * prazo.juro.com;
    parcelas.push(
      <option
        key={i}
        value={i}
        title={`Valor total do parcelamento: ${moeda(montante)}`}
        disabled={Boolean(montante / i < valorMinimo)}
      >
        {i}x de {moeda(montante / i)}
        {i <= prazo.juro.sem
          ? " (sem juros)"
          : ` (com juros de ${prazo.juro.com}%)`}
      </option>
    );
  }
  return <select {...props}>{parcelas.map((p) => p)}</select>;
};

export const gerarPagamento = (
  contrato,
  valor,
  tipo,
  forma,
  prazo = 5,
  venda = null,
  desconto = 0,
  comPedido = false
) => {
  return (
    <GeraPagamento
      contrato={contrato}
      valor={valor}
      tipo={tipo}
      forma={forma}
      prazo={prazo}
      venda={venda}
      desconto={desconto}
      comPedido={comPedido}
    />
  );
};

export const iconSelector = (
  parcforma,
  dataApi,
  classCss = "icon-pay-type"
) => {
  const forma = dataApi.filter((f) => f.id === parcforma);
  let icone = 1;
  switch (parcforma) {
    case 1:
      icone = faMoneyBill;
      break;
    case 2:
      icone = faCreditCard;
      break;
    case 3:
      icone = faMoneyCheckAlt;
      break;
    case 4:
      icone = faWallet;
      break;
    case 5:
      icone = faBarcode;
      break;
    case 6:
      icone = faQrcode;
      break;
    case 100:
      icone = faPiggyBank;
      break;
    default:
      icone = faMoneyBill;
  }
  return (
    <FontAwesomeIcon title={forma[0].forma} className={classCss} icon={icone} />
  );
};

export const HistoricoPagamento = (props) => {
  const { vencimento, pagoem } = props;
  let text, classe;
  if (pagoem !== null) {
    text = `Pago ${
      isBefore(parseISO(vencimento), new Date()) ? "" : " antes do vencimento "
    }`;
    classe = "pay-history ok";
  } else {
    if (isBefore(parseISO(vencimento), new Date())) {
      text = "Venceu ";
      classe = "pay-history danger";
    } else {
      text = "Vence ";
      classe = "pay-history pending";
    }
  }
  return (
    <span className={classe}>
      {text +
        formatDistanceToNow(parseISO(vencimento), {
          addSuffix: true,
          locale: ptBR,
        })}
    </span>
  );
};

export const PagamentoAvista = (props) => {
  const { limite, avista } = props;
  let text, title;
  if (limite && isAfter(parseISO(limite), new Date())) {
    text = ` Seu prazo para PAGAMENTO À VISTA (${moeda(
      avista
    )}) expira ${formatDistanceToNow(parseISO(limite), {
      addSuffix: true,
      locale: ptBR,
    })}`;
    title = `Você pode pagar ${moeda(
      avista
    )} em UMA PARCELA à vista até ${databoa(limite)}`;
    return <span title={title}>{text}</span>;
  } else {
    return null;
  }
};

export const iconOrderSelector = (tipo, icone, pedido, resumido = true) => {
  let classe, title;
  switch (tipo) {
    case "pedido":
      classe = "icon-order ok";
      title = `Efetuado em ${databoa(pedido.pedData)}`;
      break;
    case "financeiro":
      if (pedido.pedFinanceiro !== null) {
        classe = "icon-order ok";
        title = `Pago em ${databoa(pedido.pedFinanceiro)}`;
      } else {
        classe = "icon-order pending";
        title = `Aguardando pagamento desde ${databoa(pedido.pedData)}`;
      }
      break;
    case "processamento":
      const process =
        pedido.pedFinanceiro !== null
          ? pedido.pedProcessamento === null
            ? "pending"
            : pedido.pedOk !== null
            ? "ok"
            : "processing"
          : "";
      if (pedido.pedProcessamento !== null) {
        switch (process) {
          case "pending":
            title = `Aguardando processamento desde ${databoa(
              pedido.pedFinanceiro
            )}`;
            break;
          case "processing":
            title = `Em processamento desde ${databoa(
              pedido.pedProcessamento
            )}`;
            break;
          case "ok":
            title = `Pronto em ${databoa(pedido.pedProcessamento)}`;
            break;
          default:
            title = `Ainda não está em processamento`;
        }
      } else {
        title = `Ainda não está em processamento`;
      }
      classe = `icon-order ${process}`;
      break;
    case "ok":
      const processOk =
        pedido.pedProcessamento !== null
          ? pedido.pedOk === null
            ? "pending"
            : pedido.pedEntregue !== null
            ? "ok"
            : "processing"
          : "";
      if (pedido.pedOk !== null) {
        switch (processOk) {
          case "pending":
            title = `Aguardando finalização para entrega desde ${databoa(
              pedido.pedProcessamento
            )}`;
            break;
          case "processing":
            title = `Preparando para entrega desde ${databoa(pedido.pedOk)}`;
            break;
          case "ok":
            title = `Pronto para entrega desde ${databoa(pedido.pedOk)}`;
            break;
          default:
            title = `Ainda não está pronto`;
        }
      } else {
        title = `Ainda não está pronto`;
      }
      classe = `icon-order ${processOk}`;
      break;
    case "entregue":
      const pendingEnt = pedido.pedOk !== null ? "pending" : "";

      if (pedido.pedEntregue !== null) {
        classe = "icon-order ok";
        title = `Entregue em ${databoa(pedido.pedEntregue)}`;
      } else {
        classe = `icon-order ${pendingEnt}`;
        title =
          pendingEnt !== ""
            ? `Aguardando confirmação de entrega desde ${databoa(pedido.pedOk)}`
            : `Ainda não foi entregue`;
      }
      break;
    default:
  }
  if (resumido) {
    return <FontAwesomeIcon className={classe} icon={icone} title={title} />;
  } else {
    return (
      <span className="order-state-details">
        <FontAwesomeIcon className={classe} icon={icone} title={title} />
        <span>{title}</span>
      </span>
    );
  }
};
