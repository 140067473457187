import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { login } from "../../services/auth";
import api from "../../services/api";

import { StateContext } from "../../context/Context";

import { Recado } from "../../components/styled-components-global";
import { Form, Container } from "./styles";

class SignUp extends Component {
  static contextType = StateContext; //Cria um contexto dentro da classe

  state = {
    name: "",
    email: "",
    password: "",
    error: "",
    type: "",
  };

  handleSignUp = async (e) => {
    e.preventDefault();
    const { name, email, password } = this.state;

    if (!name || !email || !password) {
      this.setState({
        error: "Preencha todos os dados para se cadastrar",
        type: "EMPTY",
      });
    } else {
      await api
        .post("/register", { name, email, password })
        .then((response) => {
          login(response.data.token);
          this.context.actions.setUser(response.data.user);
          this.props.history.push("/");
        })
        .catch((err) => {
          this.setState(err.response.data);
        });
    }
  };

  render() {
    return (
      <Container>
        <Form onSubmit={this.handleSignUp}>
          <h2>Cadastre-se no Portal do Formando</h2>

          <span>
            Para criar seu <u>usuário do Portal</u> você precisa ter efetuado o{" "}
            <strong>Autocadastro</strong> com o link recebido da sua comissão.
            <br /> <u>Use o mesmo email</u> do seu Autocadastro.
          </span>

          {this.state.error && <p>{this.state.error}</p>}
          <input
            type="text"
            placeholder="Nome de usuário"
            onChange={(e) => this.setState({ name: e.target.value })}
            autoFocus
          />
          <input
            type="email"
            placeholder="Endereço de e-mail"
            onChange={(e) => this.setState({ email: e.target.value })}
          />
          <input
            type="password"
            placeholder="Senha"
            onChange={(e) => this.setState({ password: e.target.value })}
          />

          {this.state.error && this.state.type === "FOUND" && (
            <Recado>
              <span>
                Você já está cadastrado no Portal. Clique{" "}
                <Link to="/signin">
                  <strong>AQUI</strong>
                </Link>{" "}
                para acessar.
              </span>
            </Recado>
          )}

          <button type="submit" onClick={(e) => this.handleSignUp(e)}>
            Criar usuário
          </button>
          <hr />
          <Link
            to="/signin"
            title="Já fiz o autocadastro e já tenho usuário do portal."
          >
            Já tenho usuário. Acessar agora.
          </Link>
        </Form>
      </Container>
    );
  }
}

export default withRouter(SignUp);
