import React, { useContext, useState, useEffect } from "react";
import * as DOC from "../../../configs/consts";
import CurrencyInput from "react-currency-input";
import { StateContext } from "../../../context/Context";
import { format, addBusinessDays } from "date-fns";
import api from "../../../services/api";
import formas_api from "../../../services/formas_pagamento.json";
import {
  criaPix,
  foiPagoAvista,
  iconSelector,
  moeda,
  Parcelador,
} from "../../../services/utils";

import Endereco from "../../../components/Endereco";
import FormBoleto from "../../../components/FormBoleto";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBarcode,
  faBoxOpen,
  faCreditCard,
  faExchangeAlt,
  faFileSignature,
  faQrcode,
} from "@fortawesome/free-solid-svg-icons";
import { Gboleto } from "./styles";
import GeraPix from "../../../components/GeraPix";

export default function GeraCobrancas(props) {
  const { contrato, parcela, via, totais } = props;
  const context = useContext(StateContext);
  const [valBoleto, setValBoleto] = useState(null);
  const [showBoleto, setShowBoleto] = useState(false);
  const [showCartao, setShowCartao] = useState(false);
  const [boleto, setBoleto] = useState([]);

  const [linkPagamento, setLinkPagamento] = useState("#");
  const [forma, setForma] = useState(0);
  const [tipo, setTipo] = useState("Contrato");
  const [descAVista, setDescAVista] = useState(false);
  const [parcelasSelecionadas, setParcelasSelecionadas] = useState(1);
  const [parcelasDisponiveis, setParcelasDisponiveis] = useState(
    DOC.MAX_PARCELAS
  );
  const [showPix, setShowPix] = useState(false);
  const [dadosPix, setDadosPix] = useState([]);

  const [viewButton, setViewButton] = useState(false);
  const [viewForma, setViewForma] = useState(true);
  const [viewTipo, setViewTipo] = useState(false);
  const [viewInfos, setViewInfos] = useState(false);
  const [viewParcelas, setViewParcelas] = useState(false);

  const vencimento = addBusinessDays(new Date(), 5);
  const showRevEndereco = Boolean(!context.state.cadastro.AlRevEndereco);
  const alunoRestrito = context.state.cadastro.AlRestrito;

  useEffect(() => {
    if (via === 2) {
      setBoleto(montaBoleto(parcela, via));
      setShowBoleto(true);
    }
  }, []);

  const handleChange = (event, maskedvalue, floatvalue) => {
    setValBoleto(floatvalue);
  };

  const montaBoleto = (dados, via) => {
    const p = dados;
    const nosso = String(p.parcid);
    const dvenc = p.parcvencimento.split("-");
    const vl = p.parcvalor + parseFloat(DOC.TAXA_BOLETO);
    const val = String(vl.toFixed(2));

    return {
      idConv: DOC.ID_CONV,
      refTran: `${DOC.REF_TRAN}${nosso.padStart(10, "0")}`,
      valor: val.replace(".", ""),
      dtVenc: `${dvenc[2]}${dvenc[1]}${dvenc[0]}`,
      tpPagamento: via === 1 ? DOC.TP_PAGAMENTO : DOC.TP_VIA_2,
      indicadorPessoa: DOC.INDICADOR_PESSOA,
      tpDuplicata: DOC.TP_DUPLICATA,
      urlRetorno: DOC.URL_RETORNO,
      cpfCnpj: context.state.cadastro.AlCPF
        ? context.state.cadastro.AlCPF.replace(/[^\d]+/g, "")
        : null,
      nome: context.state.cadastro.AlNome,
      endereco: `${context.state.cadastro.AlEndereco}, ${context.state.cadastro.AlNumero} ${context.state.cadastro.AlComp}`,
      cidade: context.state.cadastro.AlCidade,
      uf: context.state.cadastro.AlUF,
      cep: context.state.cadastro.AlCEP,
      msgLoja: `Pagamento ref. Contrato ${contrato}-${p.parcid}`,
    };
  };

  const handleTrocarForma = (e) => {
    e.preventDefault();
    setViewForma(true);
    setViewTipo(false);
    setDescAVista(false);
    setParcelasSelecionadas(1);
    setValBoleto(null);
    setViewInfos(false);
    setViewButton(false);
    //setViewParcelas(false);
  };

  const handleForma = (forma) => {
    setForma(forma);
    setViewForma(false);
    setViewTipo(true);
  };

  const handleTipo = (tipo) => {
    setViewTipo(false);
    setTipo(tipo);
    setViewInfos(true);
    setViewButton(true);
    setViewParcelas(forma === 2 ? true : false);
  };

  const handleValor = (e, valor) => {
    e.preventDefault();
    setValBoleto(valor);
    setParcelasSelecionadas(1);
    setDescAVista(true);
  };

  const handleParcelaSelecionada = (e) => {
    e.preventDefault();
    setParcelasSelecionadas(e.target.value);
  };

  const handleSubmit = (e, forma) => {
    e.preventDefault();
    setViewButton(false);
    const data = {
      parcconta: contrato,
      parcvalor: valBoleto,
      parctipo: tipo,
      parcforma: forma,
      parctaxa: forma === 5 ? DOC.TAXA_BOLETO : 0,
      parctitulo: forma === 5 ? 1 : null,
      parcvencimento: format(vencimento, "yyyy-MM-dd"),
      parcdescavista: descAVista,
      parcdesconto: Number(descAVista ? totais.aprazo - totais.avista : 0),
      parcacrescimo: Number(
        forma === 2 &&
          parcelasSelecionadas >
          context.state.cadastro.AlFormDados.FormLimiteSemJuros
          ? (valBoleto / 100) * context.state.cadastro.AlFormDados.FormTaxa
          : 0
      ),
      parcadmin: "Portal",
      parcorigem: "Portal",
    };
    api.post(`pagamentos/novaparcela`, data).then((dados) => {
      if (!dados.data.error) {
        if (forma === 5) {
          setBoleto(montaBoleto(dados.data, via));
          setShowBoleto(true);
        } else if (forma === 6) {
          const code = criaPix({
            chave: DOC.CHAVE_PIX,
            mensagem: `${tipo} ${tipo === "E-commerce" ? dados.data.parcvenda : dados.parcconta
              }`,
            titular: DOC.TITULAR_PIX,
            cidade: DOC.CIDADE_PIX,
            transacao: `${dados.data.parcid}`,
            valor: dados.data.parcvalor,
          });
          api
            .post(`pagamentos/pix/${dados.data.parcid}`, { code: code })
            .then((dt) => {
              setDadosPix(dados.data);
              setShowPix(true);
            });
        } else {
          api
            .post(`pagamentos/linkCheckout`, {
              order: {
                financeiro: dados.data,
                parcelamento: { selecionado: parcelasSelecionadas },
              },
            })
            .then((dt) => {
              setLinkPagamento(dt.data.checkoutUrl);
              setShowCartao(true);
            });
        }
      }
    });
  };

  return (
    <Gboleto>
      <Endereco
        view={showRevEndereco && via === 1}
        notificacao="Você tem dados faltando em seu cadastro. Por favor complete estes dados antes de continuar."
      />
      {!showRevEndereco && via === 1 && (
        <form className="via-1">
          {viewForma && (
            <div id="menuForma">
              {!alunoRestrito ? (
                <button
                  className="icone-e-texto-vertical"
                  onClick={() => handleForma(2)}
                >
                  <FontAwesomeIcon icon={faCreditCard} /> Pagar com cartão
                </button>
              ) : null}
              <button
                className="icone-e-texto-vertical"
                onClick={() => handleForma(6)}
              >
                <FontAwesomeIcon icon={faQrcode} /> Pagar com pix
              </button>
              {!alunoRestrito ? (
                <button
                  className="icone-e-texto-vertical"
                  onClick={() => handleForma(5)}
                >
                  <FontAwesomeIcon icon={faBarcode} /> Pagar com boleto
                </button>
              ) : null}
            </div>
          )}

          {viewTipo && (
            <div id="menuTipo">
              <button
                className="icone-e-texto-vertical"
                onClick={() => handleTipo("Contrato")}
              >
                <FontAwesomeIcon icon={faFileSignature} /> Contrato
              </button>
              {totais.extras > 0 && (
                <button
                  className="icone-e-texto-vertical"
                  onClick={() => handleTipo("Extras")}
                >
                  <FontAwesomeIcon icon={faBoxOpen} /> Extras e Pacotes
                </button>
              )}
            </div>
          )}
          {showPix && (
            <GeraPix dados={dadosPix} onClick={(e) => e.stopPropagation()} />
          )}
          {viewInfos && !showPix && (
            <>
              {/* {!foiPagoAvista(context.state.financeiro[0].AlParcelas) &&
                tipo === "Contrato" && (
                  <div id="sugestoes_cartao">
                    <p className="instrucoes">Considere estas sugestões:</p>
                    <button
                      className="icone-e-texto"
                      onClick={(e) => handleValor(e, totais.avista)}
                    >
                      {iconSelector(forma, formas_api)} Pague{" "}
                      {moeda(totais.avista)} à vista e aproveite o desconto
                    </button>
                    ou
                  </div>
                )} */}
              <CurrencyInput
                placeholder="Defina um valor para fazer o pagamento"
                prefix="R$ "
                value={valBoleto}
                decimalSeparator=","
                thousandSeparator="."
                onChangeEvent={handleChange}
                disabled={descAVista}
              />

              {viewParcelas && forma === 2 && (
                <Parcelador
                  id="parcelas_cartao"
                  disabled={descAVista} //também funciona como flag para pagamento avista
                  defaultValue={parcelasSelecionadas}
                  total={valBoleto}
                  disponiveis={parcelasDisponiveis}
                  minimo={DOC.PARCELA_MINIMA}
                  prazo={{
                    data: context.state.cadastro.AlFormDados.FormData,
                    limiteCartao:
                      context.state.cadastro.AlFormDados.FormLimiteCartao,
                    juro: {
                      com: context.state.cadastro.AlFormDados.FormTaxa,
                      sem: context.state.cadastro.AlFormDados
                        .FormLimiteSemJuros,
                    },
                    avista: context.state.cadastro.AlFormDados.FormValor, //sem uso, por enquanto
                    aprazo: context.state.cadastro.AlFormDados.FormValorPrazo, //sem uso, por enquanto
                  }}
                  onChange={(e) => handleParcelaSelecionada(e)}
                />
              )}

              <p className="instrucoes">
                - O valor mínimo é {moeda(DOC.PARCELA_MINIMA)}.
                <br />- Não é necessário o envio do comprovante de pagamento à
                Produtora.
                {forma === 2 && (
                  <>
                    <br />- Tenha em mãos os dados de seu cartão.
                    <br />- Você será redirecionado ao ambiente seguro da
                    operadora para preencher os dados.
                  </>
                )}
                {forma === 5 && (
                  <>
                    <br />- Você será redirecionado ao ambiente seguro do banco
                    para obter seu boleto.
                    <br />- O valor gerado será acrescido da taxa bancária.
                    <br />- O prazo de pagamento é 5 dias à partir da geração.
                    <br />- A compensação do boleto é automática e pode levar
                    até 72hs.
                  </>
                )}
                {forma === 6 && (
                  <>
                    <br />- A confirmação do pix é manual e pode levar até 72hs.
                    <br />- Enviar o comprovante ã produtora agiliza o processo
                    de liberação.
                  </>
                )}
              </p>
            </>
          )}
          {viewButton && !showPix && (
            <>
              <button
                className="icone-e-texto"
                style={{ marginBottom: "10px", padding: "20px" }}
                disabled={valBoleto >= DOC.PARCELA_MINIMA ? "" : "disabled"}
                onClick={(e) => handleSubmit(e, forma)}
              >
                {iconSelector(forma, formas_api)} Pagar com{" "}
                {formas_api[forma].forma} agora
              </button>
              <button
                className="icone-e-texto"
                style={{ marginBottom: "10px", padding: "20px" }}
                onClick={(e) => handleTrocarForma(e)}
              >
                <FontAwesomeIcon icon={faExchangeAlt} /> Escolher outra opção
              </button>
            </>
          )}
        </form>
      )}

      {showBoleto && (
        <FormBoleto
          dados={boleto}
          showBoleto={showBoleto}
          showRevEndereco={showRevEndereco}
          via={via}
        />
      )}

      {showCartao && (
        <a id="pagCartao" href={linkPagamento} className="icone-e-texto">
          <FontAwesomeIcon icon={faCreditCard} />
          EFETUAR PAGAMENTO
        </a>
      )}
    </Gboleto>
  );
}
