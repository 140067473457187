import React from "react";

import "./styles/global.css";
import { Mensagem, Container } from "./styles/styles";
import logo from "../../assets/logoSP.png";

export default () => {
  return (
    <Container>
      <Mensagem>
        <h1>Ooops...!</h1>
        <p>
          Para acessar o auto-cadastro você precisa do link que foi enviado para
          sua comissão. Caso tenha clicado nesse link e ainda assim está vendo
          essa mensagem, é possivel que ele esteja incompleto. Qualquer dúvida
          entre em contato com a sua comissão.
        </p>
        <a className="auto" href="http://www.sp-prod.com.br">
          Enquanto isso, clique aqui e visite nosso site...
        </a>
      </Mensagem>
    </Container>
  );
};
